import axios from 'axios';
import { uri } from './restaurant_api';

export const dropshipPing = async () => {
    try {
        return await axios.get(`${uri}/dropship/ping`);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const dropshipClick = async (action) => {
    try {
        return await axios.get(`${uri}/dropship/click?action=${action}`);
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const dropshipRegister = async (data) => {
    try {
        return await axios.post(`${uri}/dropship/register`, data)
    } catch (error) {
        console.log(error);
        return null;
    }
};
