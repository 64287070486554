import React, { useState, useEffect } from 'react';
import HeroImage from '../../../assets/categories/icons/drop.png';
import { dropshipClick, dropshipPing, dropshipRegister } from '../../../api/dropship_api';

const App = () => {
  const [view, setView] = useState('hero');
  const [role, setRole] = useState('seller');
  const [openFAQ, setOpenFAQ] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [fb, setFb] = useState('');
  const [type, setType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [forumCategories, setForumCategories] = useState([]);
  const [forumMessage, setForumMessage] = useState('');

  useEffect(() => {
    dropshipPing();
  }, []);

  const scrollToSection = (section) => {
    setView(section);
    document.getElementById(section).scrollIntoView({ behavior: 'smooth' });
    dropshipClick(`scrollTo:${section}`);
  };

  const toggleRole = (selectedRole) => {
    dropshipClick(`toggleRole:${selectedRole}`);
    setRole(selectedRole);
  };

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
    dropshipClick(`toggleFAQ:${index}`);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const requestData = {
      email,
      phone,
      fb,
      type: role,
      message: forumMessage,
      categories: forumCategories
    };
    const response = await dropshipRegister(requestData);
    if (response) {
      setShowModal(true);
    }
  };

  const handleCategoryChange = (category) => {
    setForumCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((cat) => cat !== category)
        : [...prevCategories, category]
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  const handleSupportClick = () => {
    window.open('https://wa.me/+905340774506', '_blank');
  };

  const forumPromptText =
    role === 'buyer' || type === 'Ürün Bulmak ve Satmak İstiyorum'
      ? 'Alım yapmak istediğiniz kategoriler'
      : 'Satmak istediğiniz kategoriler';

  const handleTypeChange = (selectedType) => {
    setType(selectedType);
    setRole(selectedType === 'Ürün Bulmak ve Satmak İstiyorum' ? 'buyer' : 'seller');
  };

  return (
    <div className={`container ${role}`}>
      <header className="header">
        <div className="logo-container">
          <h1 className="logo">TedaBir</h1>
          <span className="motto">Bir adımda tedarik.</span>
        </div>
        <nav className="nav">
          <button onClick={() => scrollToSection('features')} className="nav-link">Özellikler</button>
          <button onClick={() => scrollToSection('faq')} className="nav-link">
            {isMobile ? 'SSS' : 'Sıkça Sorulan Sorular'}
          </button>
          <button onClick={() => scrollToSection('signup')} className="nav-link">Bekleme Listesine Kayıt Ol</button>
        </nav>
        {!isMobile && <button onClick={handleSupportClick} className="support-button">
          💬 WhatsApp İletişim
        </button>}
      </header>
      {isMobile && <button onClick={handleSupportClick} className="support-button">
        💬 WhatsApp İletişim
      </button>}
      <section className="hero" id="hero">
        <div className="text-content">
          <h1 className="hero-title">
            {role === 'buyer'
              ? "En İyi Ürünleri Bulun ve Dropshipping'e Başlayın"
              : "Dropshipping Ürünlerinizi Listeleyin ve Alıcılara Kolayca Ulaştırın!"}
            <br />
            <span className="highlight">...Otomatik ve Zahmetsiz</span>
          </h1>
          <p className="hero-subtitle">
            {role === 'buyer'
              ? "Binlerce ürün arasından seçim yapın ve tedarikçilerinizle tek tıkla çalışmaya başlayın."
              : "Artık ürünlerinizi her kanalda manuel olarak paylaşmaya veya sürekli fiyat güncellemesi yapmak zorunda kalmayın. TedaBir ile doğrulanmış alıcılara ve yeni satış kanallarına zahmetsizce ulaşın."}
          </p>
          <button className="cta-button" onClick={() => scrollToSection('signup')}>Bekleme Listesine Katıl</button>
        </div>

        <div className="image-content">
          <img src={HeroImage} alt="Hero Character" className="hero-image" />
          {role === 'buyer' ? (
            <>
              <div className="tag tag-promotions">Yüzlerce Ürün Kategorileri</div>
              <div className="tag tag-order">Akıllı Öneriler</div>
              <div className="tag tag-new">WhatsApp Grupları ve Link Üzerinden Yeni Satış Kanalları</div>
              <div className="tag tag-appointments-2">Websitesine ve E-ticarete Otomatik Entegrasyon</div>
              <div className="tag tag-occasions">Binlerce Yeni Satıcıya Ulaşma Fırsatı</div>
              <div className="tag tag-reviews-2">Promosyonlar & Güncellemeler</div>
            </>
          ) : (
            <>
              <div className="tag tag-promotions">Yüzlerce Yeni Alıcıya Ulaşma Fırsatı</div>
              <div className="tag tag-occasions">Kolay Stok ve Fiyat Güncellemeleri</div>
              <div className="tag tag-new">Otomatik Entegrasyon</div>
              <div className="tag tag-appointments">Alıcılara Anlık Bildirim</div>
              <div className="tag tag-order">Otomatik Sipariş ve Ödeme</div>
              <div className="tag tag-reviews">Yeni Satış Kanalları</div>
            </>
          )}
        </div>
      </section>

      <div className="role-toggle">
        <button onClick={() => toggleRole('seller')} className={role === 'seller' ? 'active' : ''}>Üretici / Satıcıyım</button>
        <button onClick={() => toggleRole('buyer')} className={role === 'buyer' ? 'active' : ''}>Alıcıyım</button>
      </div>

      <section className="features-section" id="features">
        <h2 className="section-title">Nasıl Çalışır</h2>
        <div className="features">
          {role === 'buyer' ? (
            <>
              <div className="feature-card">
                <h3>Geniş Ürün Seçimi</h3>
                <p>Almak istediğiniz ürünleri binlerce seçenek arasından kolayca bulun ve tek tıkla katalogunuza ekleyin.</p>
              </div>
              <div className="feature-card">
                <h3>Akıllı Entegrasyon</h3>
                <p>Fiyat ve stok bilgileri otomatik olarak güncellenir; her işlemde güncel bilgiye erişin.</p>
              </div>
              <div className="feature-card">
                <h3>Yeni Satış Kanalları</h3>
                <p>Ürünlerinizi WhatsApp grupları, sosyal medya veya linkler üzerinden satabilirsiniz, daha geniş müşteri kitlesine ulaşarak kârınızı artırın.</p>
              </div>
              <div className="feature-card">
                <h3>Satıcılarla Otomatik Entegrasyon</h3>
                <p>Satış yapıldığında sipariş satıcılarla otomatik düşer ve ödeme platform üzerinden gerçekleşir.</p>
              </div>
            </>
          ) : (
            <>
              <div className="feature-card">
                <h3>Kolay Ürün Listeleme ve Entegrasyon</h3>
                <p>İster ürünlerinizi ekleyin, ister stok sisteminizi bağlayın, alıcılar ürün, stok ve fiyat bilgilerini anında görsün.</p>
              </div>
              <div className="feature-card">
                <h3>Alıcılara Anlık Bildirimler</h3>
                <p>Fiyat veya stok değiştiğinde alıcılar hemen haberdar olur. Bu sayede müşteri memnuniyetini artırın.</p>
              </div>
              <div className="feature-card">
                <h3>Alıcılarla Otomatik Entegrasyon</h3>
                <p>Alıcılar listelenmiş ürünlerinizden kendi kataloglarına tek tıkla ürün aktarabilir. Satış yapıldığında sipariş size otomatik düşer ve ödeme platform üzerinden gerçekleşir.</p>
              </div>
            </>
          )}
        </div>
      </section>

      <section className="benefits-section" id="benefits">
        <h2 className="section-title">Faydalar</h2>
        <div className="features">
          {role === 'buyer' ? (
            <>
              <div className="feature-card">
                <h3>Kolay Tedarik</h3>
                <p>Farklı kategorilerde güvenilir satıcılardan ürünleri zahmetsizce tedarik edin.</p>
              </div>
              <div className="feature-card">
                <h3>Yapay Zeka Destekli Eşleştirme</h3>
                <p>İş ihtiyaçlarınıza uygun ürün önerileri alın.</p>
              </div>
              <div className="feature-card">
                <h3>Yeni Satış Fırsatları</h3>
                <p>WhatsApp, sosyal medya ve özel satış linkleri üzerinden ürünlerinizi daha geniş bir kitleye ulaştırın.</p>
              </div>
            </>
          ) : (
            <>
              <div className="feature-card">
                <h3>Zamandan Tasarruf Edin</h3>
                <p>Her gün XML güncellemeleri veya sosyal medya paylaşımlarıyla uğraşmadan işlerinizi tek bir yerden otomatik olarak yönetin.</p>
              </div>
              <div className="feature-card">
                <h3>Daha Fazla Müşteri ve Satış</h3>
                <p>Tüm alıcılar size kolayca ulaşsın, onların açacağı yeni satış kanallarıyla ürünlerinizi geniş bir müşteri kitlesine ulaştırın.</p>
              </div>
              <div className="feature-card">
                <h3>Kolay Sipariş ve Ödeme Yönetimi</h3>
                <p>Alıcıların siparişleri platform üzerinden size düşsün ve ödeme işlemleri doğrudan platform üzerinden gerçekleşsin.</p>
              </div>
              <div className="feature-card">
                <h3>Komisyon Yok</h3>
                <p>Satıcılar ürün listeleme için ücret ödemezler. Belli bir sipariş hacmini geçtiklerinde her sipariş üzerinden %1 komisyon öderler.</p>
              </div>
            </>
          )}
        </div>
      </section>

      <section className="faq-section" id="faq">
        <h2 className="section-title">Sıkça Sorulan Sorular</h2>
        <div className="faq-list">
          {(role === 'buyer' ? [
            { question: "Ürünleri nasıl tedarik edebilirim?", answer: "Özenle hazırlanmış kategorilere ve tüm satıcıların ürünlerine göz atarak bir tıkla ürünlerinizi kendi katalogunuza ekleyebilirsiniz." },
            { question: "Fiyatlar ve stok bilgileri güncel mi?", answer: "Evet, platformumuzda tüm bilgiler gerçek zamanlı olarak güncellenmektedir." },
            { question: "Hangi kanallardan satış yapabilirim?", answer: "Kataloglarınızı ister kendi web sitenize ister e-ticaret sayfanıza otomatik olarak aktarabilir, ayrıca ürünlerinizi katalog satış linkleri üzerinden WhatsApp ve sosyal medyada da satabilirsiniz." },
            { question: "Satıcılar kimlerdir, nasıl güvenebilirim?", answer: "Satıcılar, üretim veya toptan satış yapan kişiler ya da şirketler olabilir. Platformumuz alıcıları ve satıcıları doğrulayıp puanlar." },
          ] : [
            { question: "Ürünleri nasıl listeleyebilirim?", answer: "Ürünlerinizi excel ile ya da kendi stok sisteminizden otomasyonla kolayca yükleyin, stok ve fiyatları tek bir noktadan yönetin." },
            { question: "Sistemle entegrasyon nasıl sağlanır?", answer: "Platformumuz, stok ve fiyat bilgileriniz için otomatik entegrasyon imkanı sunar." },
            { question: "Sipariş ve ödemeler nasıl yönetiliyor?", answer: "Alıcılar, sipariş ve ödeme süreçlerini platform üzerinden otomatik olarak tamamlar." },
            { question: "Stok ve fiyat güncellemeleri alıcıya nasıl iletiliyor?", answer: "Herhangi bir değişiklikte, alıcılar anlık bildirim alır ve bilgi güncellemeleri anında yansıtılır." },
            { question: "Alıcılar benden nasıl ürün talep edebilir?", answer: "Satıcılar tek tıkla kendi kataloglarına direkt ürünlerinizden ekleyebilir veya sizden teklif alabilirler." },
            { question: "Alıcılar kimlerdir, nasıl güvenebilirim?", answer: "Alıcılar e-ticaretle uğraşan, dropshipping yapan kişiler veya bireysel olarak dropshipping yapan kullanıcılar olabilir. Platformumuz alıcıları ve satıcıları doğrulayıp puanlar." },
          ]).map((faq, index) => (
            <div key={index} className="faq-item">
              <button className="faq-question" onClick={() => toggleFAQ(index)}>
                {faq.question}
                <span className="arrow">{openFAQ === index ? '▲' : '▼'}</span>
              </button>
              {openFAQ === index && <p className="faq-answer">{faq.answer}</p>}
            </div>
          ))}
        </div>
      </section>

      <section className="signup-section" id="signup">
        <h2 className="signup-title">
          {role === 'buyer' ? "Dropshipping'e Bugün Başlayın" : "Ürünlerinizi Listeleyin ve Satış Yapmaya Hazır Olun"}
        </h2>
        <p className="signup-subtitle">Erken kayıt olanlara 1 yıl boyunca komisyon yok! Lansman yakında, tarih için beklemede kalın!</p>
        <form className="signup-form" onSubmit={handleRegister}>
          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                checked={type === 'Ürünlerimi Satmak İstiyorum'}
                onChange={() => handleTypeChange('Ürünlerimi Satmak İstiyorum')}
              />
              Ürünlerimi Satmak İstiyorum
            </label>
            <label>
              <input
                type="checkbox"
                checked={type === 'Ürün Bulmak ve Satmak İstiyorum'}
                onChange={() => handleTypeChange('Ürün Bulmak ve Satmak İstiyorum')}
              />
              Ürün Bulmak ve Satmak İstiyorum
            </label>
          </div>
          <input
            placeholder="E-posta Adresiniz"
            className="input-field"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            placeholder="Facebook Profil URL'niz"
            className="input-field"
            value={fb}
            onChange={(e) => setFb(e.target.value)}
          />
          <input
            placeholder="Telefon Numaranız"
            className="input-field"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          <h3>{forumPromptText}</h3>
          <div className="category-checkbox-group">
            {['Elektronik', 'Moda', 'Ev ve Yaşam', 'Sağlık ve Güzellik', 'Anne ve Bebek', 'Spor ve Outdoor', 'Oyuncaklar'].map((category) => (
              <label key={category} className="checkbox-label">
                <input
                  type="checkbox"
                  value={category}
                  checked={forumCategories.includes(category)}
                  onChange={() => handleCategoryChange(category)}
                />
                {category}
              </label>
            ))}
          </div>
          <textarea
            value={forumMessage}
            onChange={(e) => setForumMessage(e.target.value)}
            placeholder="Bize iletmek istediğiniz bir mesaj varsa lütfen yazın."
            className="forum-textarea"
          />
          <button type="submit" className="signup-button styled-button">Erken Erişim İçin Kayıt Ol</button>
        </form>
      </section>

      <footer className="footer">
        <p>©2024 TedaBir</p>
      </footer>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Başarılı Kayıt!</h2>
            <p>Lansmanımız başladığında size ulaşacağız. İlginiz için teşekkürler!</p>
            <button onClick={handleCloseModal} className="close-button">Kapat</button>
          </div>
        </div>
      )}

      <style jsx="true">{`
        .container {
          font-family: Arial, sans-serif;
          color: #333;
          margin: 0 auto;
        }

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 140px;
        }
        
        .logo-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .logo {
          font-size: 2rem;
          font-weight: bold;
          color: #ff7a00;
        }

        .motto {
          font-size: 0.9rem;
          font-weight: normal;
          color: #666;
          margin-top: -10px;
          margin-left: 4px;
        }

        .nav button {
          margin-left: 20px;
          font-size: 1rem;
          color: #333;
          background: none;
          border: none;
          cursor: pointer;
          text-decoration: none;
        }

        .hero {
          display: flex;
          align-items: center;
          padding: 0px 20px 100px 140px;
          max-width: 1200px;
        }

        .text-content {
          flex: 1;
          max-width: 600px;
        }

        .hero-title {
          font-size: 3rem;
          font-weight: 700;
          line-height: 1.2;
        }

        .highlight {
          color: #ff7a00;
        }

        .hero-subtitle {
          font-size: 1.1rem;
          color: #666;
          margin: 20px 0;
        }

        .cta-button {
          padding: 12px 20px;
          border: 2px solid #ff7a00;
          background: transparent;
          color: #ff7a00;
          font-size: 1rem;
          font-weight: bold;
          border-radius: 25px;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .cta-button:hover {
          background-color: #ff7a00;
          color: white;
        }

        .image-content {
          position: relative;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 70px;
        }

        .hero-image {
          width: 500px;
          height: 500px;
          border-radius: 50%;
          object-fit: cover;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
        }

        .tag {
          position: absolute;
          background-color: white;
          border: 2px solid #ff7a00;
          border-radius: 20px;
          padding: 8px 15px;
          font-size: 0.9rem;
          font-weight: bold;
          color: #333;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        }

        .tag-promotions {
          top: 18%;
          left: -20px;
        }

        .tag-occasions {
          bottom: 20%;
          left: 0px;
        }

        .tag-appointments {
          top: 10%;
          right: 50px;
        }

        .tag-appointments-2 {
          top: 10%;
          right: 50px;
        }

        .tag-new {
          right: -100px;
        }

        .tag-order {
          bottom: -10px;
        }

        .tag-order-2 {
          bottom: -10px;
        }

        .tag-reviews {
          bottom: 10%;
          right: 0px;
        }

        .tag-reviews-2 {
          bottom: 10%;
          right: -100px;
        }

        .features-section {
          padding: 50px 20px 80px 20px;
          background-color: #1b1e27;
          color: white;
          text-align: center;
          border-radius: 20px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }

        .features {
          margin-top: 50px;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          gap: 20px;
          justify-items: center;
        }

        .feature-card {
          background-color: white;
          color: #333;
          padding: 20px;
          border-radius: 12px;
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
          max-width: 300px;
          text-align: center;
        }

        .feature-card h3 {
          font-size: 1.3rem;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .feature-card p {
          font-size: 1rem;
          color: #666;
        }

        .benefits-section {
          padding: 80px 20px;
          text-align: center;
          border-radius: 20px;
          background-color: #f9f9f9;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }

        .faq-section {
          padding: 60px 20px;
          text-align: center;
        }

        .faq-list {
          max-width: 800px;
          margin: 0 auto;
        }

        .faq-item {
          background-color: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          margin-bottom: 10px;
          overflow: hidden;
        }

        .faq-question {
          width: 100%;
          background: none;
          border: none;
          padding: 15px 20px;
          font-size: 1rem;
          font-weight: 500;
          color: #333;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
        }

        .signup-section {
          background-color: #ff7a00;
          color: white;
          padding: 50px 20px;
          text-align: center;
          border-radius: 20px;
          box-shadow: 0 6px 14px rgba(0, 0, 0, 0.15);
        }

        .signup-title {
          font-size: 2rem;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .signup-subtitle {
          font-size: 1.1rem;
          margin-bottom: 10px;
          font-weight: 500;
        }

        .signup-instruction {
          font-size: 1rem;
          margin-bottom: 30px;
          font-weight: 400;
        }

        .signup-form {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;
          max-width: 550px;
          margin: 0 auto;
        }

        .email-input {
          padding: 12px 20px;
          font-size: 1rem;
          border: none;
          border-radius: 25px;
          outline: none;
          width: 250px;
          max-width: 300px;
        }

        .signup-button {
          padding: 15px 30px;
          font-size: 1.1rem;
          font-weight: bold;
          color: #ff7a00;
          background-color: white;
          border: none;
          border-radius: 25px;
          cursor: pointer;
          transition: background-color 0.3s ease, color 0.3s ease;
        }

        .signup-button:hover {
          background-color: #ff7a00;
          color: white;
        }

        .input-field {
          width: 100%;
          padding: 12px 15px;
          font-size: 1rem;
          border: none;
          border-radius: 25px;
          outline: none;
          background-color: white;
          color: #333;
        }

        .footer {
          background-color: #1b1e27;
          color: white;
          padding: 20px 0;
          text-align: center;
          font-size: 0.85rem;
        }

        .role-toggle {
          display: flex;
          justify-content: center;
          margin: 20px 0;
          gap: 10px;
        }

        .role-toggle button {
          padding: 16px 40px;
          font-size: 1.25rem;
          font-weight: bold;
          color: #333;
          background-color: #f0f0f0;
          border: 1px solid #ccc;
          border-radius: 25px;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }

        .role-toggle .active {
          background-color: #ff7a00;
          color: white;
          border: 1px solid #ff7a00;
        }
        
        .faq-answer {
          padding-left: 20px;
          text-align: left;
        }

        .checkbox-group {
          display: flex;
          margin-bottom: 20px;
          gap:50px;
        }

        .checkbox-group label {
          display: flex;
          align-items: center;
          font-size: 1rem;
          color: #333;
        }

        .checkbox-group input[type="checkbox"] {
          margin-right: 10px;
          transform: scale(1.3);
        }

        .signup-button.styled-button {
          background-color: #25d366;
          color: white;
          border: none;
          padding: 12px 24px;
          font-size: 1rem;
          font-weight: bold;
          border-radius: 20px;
          cursor: pointer;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
          transition: transform 0.2s ease, box-shadow 0.2s ease;
        }

        .signup-button.styled-button:hover {
          transform: translateY(-2px);
          box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
        }

        .select-field {
          width: 100%;
          padding: 12px 15px;
          border-radius: 25px;
          border: 1px solid #ccc;
          font-size: 1rem;
        }

        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
          animation: fadeIn 0.3s ease-in-out;
        }

        .modal-content {
          background: white;
          padding: 20px 40px;
          border-radius: 10px;
          text-align: center;
          max-width: 500px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
          animation: slideUp 0.3s ease-in-out;
        }

        .modal-content h2 {
          font-size: 1.5rem;
          margin-bottom: 15px;
        }

        .close-button {
          background-color: #25d366;
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 20px;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }

        .close-button:hover {
          background-color: #0056b3;
        }

        .support-button {
          background-color: #25d366;
          color: white;
          padding: 10px 15px;
          border-radius: 25px;
          font-size: 1rem;
          cursor: pointer;
          border: none;
          outline: none;
          transition: background-color 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .support-button:hover {
          background-color: #128c7e;
        }

        .select-field {
          width: 100%;
          padding: 12px 15px;
          border-radius: 5px;
          border: 1px solid #ccc;
          font-size: 1rem;
          min-height: 120px;
        }
        
        .category-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 20px;
      }

      .checkbox-label {
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #333;
        cursor: pointer;
      }

      .checkbox-label input[type="checkbox"] {
        margin-right: 8px;
        transform: scale(1.2);
      }
      
      .forum-textarea {
        width: 100%;
        padding: 12px;
        border-radius: 5px;
        font-size: 1rem;
        min-height: 100px;
        margin-top: 10px;
        border: 1px solid #ccc;
        outline: none;
      }

        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        @keyframes slideUp {
          from { transform: translateY(20px); }
          to { transform: translateY(0); }
        }

        @media (max-width: 768px) {
        .container {
                  font-family: Arial, sans-serif;
                  color: #333;
                }

          .header {
            display: flex;
            align-items: center;
            padding: 20px;
          }

          .logo-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-bottom: 10px;
            margin-right: 10px;
          }

          .logo {
            font-size: 1.5rem;
            text-align: center;
          }

          .motto {
            font-size: 0.8rem;
            text-align: center;
            margin-left: -10px;
          }

          .nav {
            display: flex;
            gap: 15px;
            justify-content: center;
            flex-wrap: nowrap;
            width: 100%;
          }

          .nav-link {
            font-size: 0.9rem;
            text-align: center;
          }

          .nav-link:nth-child(3) {
            width: 100%;
            margin-top: 10px;
          }

          .hero {
            flex-direction: column;
            padding: 20px;
          }

          .checkbox-group {
            display: flex;
            margin-bottom: 20px;
            gap:30px;
          }

          .text-content {
            max-width: 100%;
            margin-bottom: 20px;
            margin-top: -30px;
          }

          .hero-title {
            font-size: 2rem;
            line-height: 1.2;
          }

          .hero-subtitle {
            font-size: 1rem;
            color: #666;
            margin-top: 10px;
          }

          .tag-promotions {
                  top: 18%;
                  left: -75px;
                }

                .tag-appointments {
                  top: -4%;
                  right: 50px;
                }

                .tag-appointments-2 {
                  top: -8%;
                  right: -40px;
                }

                .tag-occasions {
                  bottom: 20%;
                  left: -75px;
                }

                .tag-order {
                  bottom: -40px;
                }

                .tag-order-2 {
                  bottom: -50px;
                  left: -110px;
                }

                .tag-reviews-2 {
                  bottom: 4%;
                  right: -40px;
                }

                .tag-reviews {
                  bottom: 4%;
                  right: -40px;
                }

                .tag-new {
                  right: -40px;
                }

          .cta-button {
            padding: 10px 16px;
            font-size: 0.9rem;
            border-radius: 20px;
          }

          .image-content {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
          }

          .hero-image {
            width: 250px;
            height: 250px;
            border-radius: 50%;
          }

          .role-toggle {
            gap: 10px;
            width: 100%;
            justify-content: center;
            margin-top: 50px;
          }

          .features-section,
          .benefits-section,
          .faq-section,
          .signup-section {
            padding: 20px;
          }

          .features {
            grid-template-columns: 1fr;
            gap: 15px;
          }

          .signup-title {
            font-size: 1.5rem;
          }

          .signup-form {
            width: 100%;
          }

          .input-field {
            width: 100%;
          }

          .faq-question {
            font-size: 0.9rem;
          }

          .footer {
            font-size: 0.75rem;
          }

          .support-button {
            background-color: #25d366;
            color: white;
            border-radius: 25px;
            font-size: 0.5rem;
            cursor: pointer;
            border: none;
            outline: none;
            transition: background-color 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
          }
          .support-button:hover {
            background-color: #128c7e;
          }
        }
      `}</style>
    </div>
  );
};

export default App;
